<template>
  <div class="page">
    <Navbar title="职位明细" :callback="back" type="SMP" />
    <Login ref="login" :callback="init" />
    <van-swipe class="top-img" :autoplay="3000" indicator-color="white">
      <van-swipe-item
        ><img
          :src="require('../../../../assets/images/recruit/bn01.jpg')"
          class="img"
      /></van-swipe-item>
    </van-swipe>
    <van-row class="simple">
      <van-col span="24">
        <van-row class="name">
          <van-col span="24">{{ task.name }}</van-col>
        </van-row>
        <van-row class="label">
          <van-col span="24">
            <span
              class="item bgc-gray"
              v-for="lb in task.label"
              :key="lb.name"
              >{{ lb.name }}</span
            >
          </van-col>
        </van-row>
        <van-row class="salary">
          <van-col span="18" class="fc-orange"
            >薪资：{{ task.salaryMin }}-{{ task.salaryMax }}/<span
              v-if="task.salaryType === 'YER'"
              >年</span
            ><span v-if="task.salaryType === 'MOT'">月</span
            ><span v-if="task.salaryType === 'DAY'">日</span></van-col
          >
          <van-col span="6" class="ta-center region">西安市</van-col>
        </van-row>
        <van-row class="time">
          <van-col span="18"
            >有效期：{{ task.timeStart }} 到 {{ task.timeEnd }}</van-col
          >
          <van-col span="6" class="ta-center amount"
            >数量:{{ task.amount }}人</van-col
          >
        </van-row>
        <div class="hr"></div>
        <van-row>
          <van-col span="12" class="price"
            >预付费用：<span class="number color-s2">0元</span></van-col
          >
          <van-col span="12" class="button">
            <van-button
              class="btn"
              icon="sign"
              :color="COLOR_S1"
              size="mini"
              @click="signSubmit"
              >立即报名</van-button
            >
          </van-col>
        </van-row>
        <van-row>
          <van-col span="12" class="price"
            >推荐奖励：<span class="number color-s2">100元</span></van-col
          >
          <van-col span="12" class="button">
            <van-button
              class="btn"
              icon="share-o"
              :color="COLOR_M"
              size="mini"
              @click="shareImage()"
              >立即推荐</van-button
            >
          </van-col>
        </van-row>
      </van-col>
    </van-row>
    <!-- <van-row class="advert" @click="onlineDetail()">
      <van-col span="24">
        <img
          :src="require('../../../../assets/images/enroll/adv.jpg')"
          class="img"
        />
      </van-col>
    </van-row> -->
    <div class="detail">
      <van-tabs :active="activeName" :color="COLOR_M" :border="true">
        <van-tab
          title="详情介绍"
          name="introduce"
          v-if="JSON.stringify(introduce) !== '{}'"
        >
          <div
            class="pre-text ta-left content"
            v-html="introduce.content"
          ></div>
        </van-tab>
        <van-tab
          title="薪资福利"
          name="welfare"
          v-if="JSON.stringify(welfare) !== '{}'"
        >
          <div class="pre-text ta-left content">{{ welfare.content }}</div>
        </van-tab>
        <van-tab
          title="工作内容"
          name="job"
          v-if="JSON.stringify(job) !== '{}'"
        >
          <div class="pre-text ta-left content">{{ job.content }}</div>
        </van-tab>
        <van-tab
          title="报名方式"
          name="signup"
          v-if="JSON.stringify(signup) !== '{}'"
        >
          <div class="pre-text ta-left content" v-if="signup.display === 'YES'">
            {{ place.content }}
          </div>
          <div v-if="signup.display === 'NO'" class="pay">
            <div class="tips fc-orange">支付(5元)立即查看报名方式</div>
            <div>
              <van-button
                class="btn"
                :color="COLOR"
                size="mini"
                icon="cart-o"
                @click="payExpense()"
                >立即支付</van-button
              >
            </div>
          </div>
        </van-tab>
        <van-tab
          title="条件要求"
          name="requ"
          v-if="JSON.stringify(requ) !== '{}'"
        >
          <div class="pre-text ta-left content">{{ requ.content }}</div>
        </van-tab>
        <van-tab
          title="工作地点"
          name="place"
          v-if="JSON.stringify(place) !== '{}'"
        >
          <div class="pre-text ta-left content">{{ place.content }}</div>
        </van-tab>
        <!-- <van-tab title="企业介绍" name="major">无 </van-tab> -->
        <van-tab
          title="其他"
          name="other"
          v-if="JSON.stringify(other) !== '{}'"
        >
          <div class="pre-text ta-left content">{{ other.content }}</div>
        </van-tab>
      </van-tabs>
    </div>
    <van-popup
      :show="shareShow"
      @click-overlay="shareShow = false"
      @click="shareShow = false"
      style="background-color: transparent; width: 100%; height: 100%"
    >
      <img
        class="shareImg"
        :src="require('../../../../assets/images/online/share-top.png')"
      />
    </van-popup>
    <Clue ref="clue" />
    <Share ref="share" />
    <Tabbar active="home" v-if="role !== 'SEL'" />
    <LogVisit
      ref="logVisit"
      module-type="RCT"
      module-label="RCT_DETAIL_INDEX"
    />
    <Loading :show="loadingShow" />
  </div>
</template>
<script>
import Index from './Index.js'
export default {
  ...Index
}
</script>
<style lang="less" scoped>
@import './Index.less';
</style>
