import { Swipe, SwipeItem, Tabs, Tab, Sticky, Tag, Overlay, Popup, Icon } from 'vant'
import Navbar from '../../common/Navbar.vue'
import Share from '../../common/Share.vue'
import Tabbar from '../common/Tabbar.vue'
import LogVisit from '../../common/LogVisit.vue'
import Login from '../../intercept/Login.vue'
import Loading from '../../common/Loading.vue'
import Clue from '../../common/Clue.vue'
export default {
    components: {
        Navbar: Navbar,
        Share: Share,
        Tabbar: Tabbar,
        Loading: Loading,
        LogVisit: LogVisit,
        Login: Login,
        Clue: Clue,
        [Swipe.name]: Swipe,
        [SwipeItem.name]: SwipeItem,
        [Tabs.name]: Tabs,
        [Tab.name]: Tab,
        [Sticky.name]: Sticky,
        [Tag.name]: Tag,
        [Overlay.name]: Overlay,
        [Popup.name]: Popup,
        [Icon.name]: Icon
    },
    data () {
        return {
            code: '',
            operator: { name: '', address: '', linkPerson: '', linkPhone: '', linkWechat: '', type: '' },
            loadingShow: false,
            shareShow: false,
            activeName: 'school',
            role: 'CLT',
            taskCode: '',
            personCode: '',
            task: {},
            onlineCode: '',
            introduce: {},
            job: {},
            requ: {},
            welfare: {},
            place: {},
            signup: {},
            other: {},
            payOrderCode: ''
        }
    },
    mounted () {
        var query = this.$route.query
        this.taskCode = query.taskCode
        this.$refs.login.validate()
    },
    methods: {
        init () {
            this.retrieveTask()
            this.$refs.logVisit.createLog()
            var personCode = window.sessionStorage.getItem('member_person_chose')
            if (personCode !== null && personCode !== undefined && personCode !== '') {
                this.personCode = personCode
                window.sessionStorage.removeItem('member_person_chose')
                this.createOrder()
            }
        },
        initShare () {
            var title = this.task.promoTitle
            var desc = this.task.promoDesc
            var link = 'https://moc.utopanet.com/recruit/consult?taskCode=' + this.taskCode
            if (this.role === 'SEL') {
                link = link + '&refereeType=SEL'
            }
            if (this.role === 'CLT') {
                link = link + '&refereeType=CLT'
            }
            var logo = 'https://moc.utopanet.com/static/logo.png'
            this.$refs.share.initParam(title, desc, link, logo)
        },
        async retrieveTask () {
            this.loadingShow = true
            var that = this
            var pd = { taskCode: that.taskCode }
            const { data: res } = await this.$http.post(this.BMS_URL + '/recruit/taskAPC/retrieveTask', this.$qs.stringify(pd))
            if (res.status === '200') {
                this.createViewClue()
                this.task = res.data
                var detail = res.data.detail
                detail.forEach(element => {
                    if (element.type === 'ITD') {
                        that.introduce = element
                    } else if (element.type === 'JOB') {
                        that.job = element
                    } else if (element.type === 'REQ') {
                        that.requ = element
                    } else if (element.type === 'WFE') {
                        that.welfare = element
                    } else if (element.type === 'PAE') {
                        that.place = element
                    } else if (element.type === 'SGP') {
                        that.signup = element
                    } else if (element.type === 'OTH') {
                        that.other = element
                    }
                })
                this.initShare()
            }
            this.loadingShow = false
        },
        signSubmit () {
            var vad = this.validateToken()
            if (vad) {
                this.$dialog.confirm({
                    title: '提示',
                    message: '确认立即报名吗？'
                })
                    .then(() => {
                        // on confirm
                        var backKey = window.const.global.MEMBER_PERSON_MATCH_BACK
                        window.sessionStorage.setItem(backKey, window.location.href)
                        this.$router.push({ path: '/mde/member/personMatch', query: { taskCode: this.taskCode, business: 'RCT' } })
                    })
            }
        },
        async createOrder () {
            var vad = this.validateToken()
            if (vad) {
                this.loadingShow = true
                var operator = window.sessionStorage.getItem(this.SESSION_OPERATOR)
                var seller = window.sessionStorage.getItem(this.SESSION_SELLER)
                var referee = window.sessionStorage.getItem(this.SESSION_REFEREE)
                var refereeType = window.sessionStorage.getItem(this.SESSION_REFEREE_TYPE)
                var site = window.sessionStorage.getItem(this.SESSION_SITE)
                var source = window.sessionStorage.getItem(this.SESSION_SOURCE)
                var pd = {
                    taskCode: this.taskCode,
                    personCode: this.personCode,
                    operatorCode: operator,
                    sellerCode: seller,
                    refereeCode: referee,
                    refereeType: refereeType,
                    siteCode: site,
                    source: source
                }
                const { data: res } = await this.$http.post(this.BMS_URL + '/recruit/order/createOrder', this.$qs.stringify(pd))
                this.loadingShow = false
                if (res.status === '200') {
                    this.createOrderClue(res.data.code)
                    this.$dialog.alert({
                        title: '职位申请',
                        message: '职位申请成功,请耐心等待或到店咨询'
                    })
                } else {
                    this.$dialog.alert({
                        title: '申请失败',
                        message: res.msg
                    })
                }
            }
        },
        validateToken () {
            var token = window.sessionStorage.getItem(this.SESSION_TOKEN)
            if (token === null) {
                this.$router.push({ path: '/bind' })
                return false
            } else {
                return true
            }
        },
        async login () {
            var pd = { code: this.code, source: 'WPP' }
            var { data: res } = await this.$http.post(this.UMS_URL + '/customer/user/loginBind', this.$qs.stringify(pd))
            if (res.status === '200') {
                window.sessionStorage.setItem(this.SESSION_TOKEN, res.data.token)
                window.sessionStorage.setItem(this.SESSION_USER, res.data.userCode)
                window.sessionStorage.setItem(this.SESSION_SERIAL, res.data.openid)
                this.init()
            } else if (res.code === '4002') {
                window.sessionStorage.setItem(this.SESSION_SERIAL, res.data.openid)
                this.$router.push({ path: '/mde/bind' })
            }
        },
        async payExpense () {
            var serial = window.sessionStorage.getItem(this.SESSION_SERIAL)
            var pd = {
                appid: 'wxa8145a74e5f2b39a',
                amount: 500,
                attach: this.taskCode,
                description: '报名方式查看支付',
                body: '报名方式查看支付',
                detail: '',
                business: this.taskCode,
                openid: serial
            }
            const { data: res } = await this.$http.post(this.UMS_URL + '/system/pay/createJsapiPay', this.$qs.stringify(pd))
            if (res.status === '200') {
                var that = this
                this.payOrderCode = res.data.code
                var param = res.data.param
                window.WeixinJSBridge.invoke(
                    'getBrandWCPayRequest', param,
                    function (rs) {
                        if (rs.err_msg === 'get_brand_wcpay_request:ok') {
                            that.retrievePayStatus()
                        }
                    })
            }
        },
        async retrievePayStatus () {
            var pd = { appid: 'wxa8145a74e5f2b39a', code: this.payOrderCode }
            const { data: res } = await this.$http.post(this.UMS_URL + '/system/pay/retrievePayStatus', this.$qs.stringify(pd))
            if (res.status === '200') {
                this.createTaskViewPay()
            }
        },
        async createTaskViewPay () {
            var pd = { taskCode: this.taskCode, business: 'SGP', payCode: this.payOrderCode }
            const { data: res } = await this.$http.post(this.BMS_URL + '/recruit/task/taskViewPay', this.$qs.stringify(pd))
            if (res.status === '200') {
                this.retrieveTask()
            }
        },
        retHome () {
            this.$router.push('/mde/main/home/recruit')
        },
        telphone () {
            var phone = this.store.linkPhone
            window.location.href = 'tel:' + phone
        },
        showWechat () {
            this.wechatShow = true
        },
        shareImage () {
            this.$router.push({ path: '/mde/recruit/shareImage', query: { taskCode: this.taskCode } })
        },
        createViewClue () {
            alert(1)
            var param = { taskCode: this.taskCode, url: window.location.href }
            var content = '补岗招聘浏览'
            this.$refs.clue.createClue('RECRUIT_VIEW_RECRUIT', this.code, JSON.stringify(param), content)
        },
        createOrderClue (orderCode) {
            var param = { taskCode: this.taskCode, personCode: this.personCode, orderCode: orderCode, url: window.location.href }
            var content = '补岗招聘订单'
            this.$refs.clue.createClue('RECRUIT_ORDER_RECRUIT', orderCode, JSON.stringify(param), content)
        },
        back () {
            var backurl = window.sessionStorage.getItem(this.SESSION_BACKURL)
            window.location.href = backurl
        }
    }
}
